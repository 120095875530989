<template>
  <tp-modal
    name="modal-add-reason"
    width="400px"
    @click:outside="handleClickOutSide"
  >
    <v-card class="border-0">
      <div class="card px-5 py-3 align-center d-flex" height="56x">
        <div class="font-weight-bold">Lý do lùi lịch</div>
        <v-spacer></v-spacer>
      </div>
    </v-card>

    <div
      class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
    >
      <v-card class="pa-5">
        <div class="mb-4">
          <v-textarea
            v-model="reason"
            class="text-body-1"
            dense
            hide-details="auto"
            placeholder="Mô tả"
            outlined
          ></v-textarea>
        </div>

        <div class="d-flex align-center justify-center">
          <v-btn
            class="rounded-lg mt-5 px-7"
            color="primary"
            depressed
            @click="handleReason"
          >
            Lưu
          </v-btn>
        </div>
      </v-card>
    </div>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      reason: ""
    };
  },

  methods: {
    handleReason() {
      this.$emit("addReason", this.reason);
      this.reason = "";
      this.$modal.hide({ name: "modal-add-reason" });
    },
    handleClickOutSide() {
      this.reason = "";
    }
  }
};
</script>

<style scoped lang="scss">
.border-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-card {
  box-shadow: none !important;
}
</style>
