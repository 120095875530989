<template>
  <v-card class="card-wrap pa-5" flat>
    <!-- Start: Created employee and branch -->
    <div class="">
      <span class="font-weight-bold">Tạo bởi: </span>
      <span>{{
        offBizDetail && offBizDetail.created_by_user
          ? offBizDetail.created_by_user.name
          : user.name
      }}</span>
    </div>
    <div class="mt-2">
      <span class="font-weight-bold">Thời gian tạo: </span>
      <template v-if="offBizDetail.id">{{ offBizDetail.created_at }}</template>
      <template v-else>{{ getToday() }}</template>
    </div>
    <div class="mt-4">
      <div class="font-weight-bold mb-2">Hạn xử lý</div>
      <div class="d-flex flex-col">
        <div>
          <tp-input-date
            v-model="dateDueDate"
            custom-class="text-body-1 mt-0"
            dense
            hide-details
            outlined
            placeholder="Chọn ngày"
            appendIcon="mdi-calendar"
            :disabled="offBizDetail.step === 4"
            @input="changeDueDate"
            @open-picker="openPicker"
            @close-picker="closePicker"
          ></tp-input-date>
        </div>

        <div
          v-show="offBizDetail.reason && offBizDetail.id"
          :key="key"
          class="text-body-2 mt-1"
        >
          Lý do lùi lịch : {{ offBizDetail.reason }}
        </div>
      </div>
    </div>

    <div
      v-if="
        offBizDetail.histories_due_date &&
          offBizDetail.histories_due_date.length
      "
      class="d-flex flex-col flex-column-reverse gap-3 mt-3"
      style="max-height: 600px; overflow-y: auto;"
    >
      <v-card
        v-for="(item, index) in offBizDetail.histories_due_date"
        :key="index + item.new_due_date"
        class="d-flex flex-col gap-1 pa-4"
        color="grey lighten-4"
        flat
      >
        <div class="d-flex align-center gap-1">
          <span class="font-weight-bold">Lùi lịch lần {{ index + 1 }}: </span>
          <span> {{ item.user_name }} </span>
        </div>

        <div class="d-flex gap-3 align-center flex-wrap">
          <div class="d-flex flex-col flex-shrink-0">
            <span class="font-weight-bold">Hạn cũ:</span>
            <span>{{ item.old_due_date }}</span>
          </div>
          <div class="d-flex flex-col flex-shrink-0">
            <span class="font-weight-bold">Hạn mới:</span>
            <span>{{ item.new_due_date }}</span>
          </div>
        </div>

        <div class="d-flex align-center gap-1">
          <span class="font-weight-bold">Lý do: </span>
          <span> {{ item.reason }} </span>
        </div>
      </v-card>
    </div>

    <v-divider class="mt-5 mb-5"></v-divider>

    <div class="mt-2">
      <div class="font-weight-bold mb-2">Chi nhánh</div>
      <v-autocomplete
        v-model="offBizDetail.branch_id"
        class="tp-filter-autocomplete"
        clearable
        :items="branches"
        dense
        :disabled="
          offBizDetail.selected_action !== 1 ||
            (offBizDetail && offBizDetail.step === 4)
        "
        hide-details
        hide-selected
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        outlined
        single-line
        label="Outlined"
        placeholder="Chọn chi nhánh"
      ></v-autocomplete>
    </div>
    <!-- End: Created employee and branch -->
    <!-- Start: Assignee -->
    <div class="mt-4">
      <div class="font-weight-bold mb-2">Nhân viên phụ trách</div>
      <v-autocomplete
        v-model="offBizDetail.assigned_to_id"
        class="tp-filter-autocomplete"
        clearable
        :items="employees"
        dense
        :disabled="
          offBizDetail.selected_action !== 1 ||
            (offBizDetail && offBizDetail.step === 4)
        "
        hide-details
        hide-selected
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        outlined
        single-line
        label="Outlined"
        placeholder="Chọn nhân viên"
      ></v-autocomplete>
    </div>
    <!-- End: Assignee -->
    <!-- Start: Assignee -->
    <div class="mt-4">
      <div class="font-weight-bold mb-2">Người theo dõi</div>
      <v-autocomplete
        v-model="offBizDetail.supervisor_id"
        class="tp-filter-autocomplete"
        clearable
        :items="employeesSameBranchWithUser"
        dense
        hide-details
        :disabled="
          offBizDetail.selected_action !== 1 ||
            (offBizDetail && offBizDetail.step === 4)
        "
        hide-selected
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        outlined
        single-line
        label="Outlined"
        placeholder="Chọn nhân viên"
      ></v-autocomplete>
    </div>
    <div v-if="offBizDetail && offBizDetail.participants">
      <div
        v-for="(participant, index) in offBizDetail.participants"
        :key="`participant-${index}`"
      >
        <v-divider class="my-3"></v-divider>
        <div>
          <div class="font-weight-bold d-flex align-center mb-2">
            <span>{{ getNameRole(participant.role) }}</span>
          </div>
          <v-autocomplete
            v-model="participant.user_id"
            class="font-weight-medium text-body-1 price--text-right mt-0 pt-0"
            dense
            flat
            hide-details
            :items="employees"
            item-text="name"
            item-value="id"
            no-data-text="Không có dữ liệu"
            placeholder="Kỹ thuật viên"
            disabled
          ></v-autocomplete>
          <v-text-field
            v-if="participant.role === 'SHIPPING'"
            v-model="participant.value"
            class="text-body-1 mt-4"
            clearable
            dense
            hide-details="auto"
            outlined
            placeholder="Quãng đường (km)"
            disabled
          ></v-text-field>
        </div>
        <div class="mt-4">
          <v-textarea
            v-model="participant.note"
            class="text-body-1 mt-2"
            auto-grow
            hide-details
            outlined
            rows="3"
            single-line
            placeholder="Ghi chú"
            disabled
          ></v-textarea>
        </div>
      </div>
    </div>
    <v-btn
      block
      class="rounded-lg my-4"
      color="primary"
      depressed
      @click="showModalAddRoleServer"
      v-if="offBizDetail.id"
    >
      <v-icon left>mdi-plus</v-icon> Thêm phục vụ
    </v-btn>
    <!-- End: Assignee -->
    <v-divider class="mt-5 mb-4"></v-divider>
    <!-- Start: Serial -->
    <div>
      <div class="font-weight-bold mb-2">
        <template v-if="offBizDetail.id"> Thông tin </template>
        <template v-else> Nhập serial</template>
      </div>
      <!--      <v-autocomplete-->
      <!--        v-if="!offBizDetail.id"-->
      <!--        v-model="serialNumber"-->
      <!--        class="tp-filter-autocomplete text-body-1 my-2"-->
      <!--        dense-->
      <!--        deletable-chips-->
      <!--        :items="serialInfo"-->
      <!--        item-text="serial_number"-->
      <!--        item-value="id"-->
      <!--        hide-details-->
      <!--        hide-selected-->
      <!--        multiple-->
      <!--        no-data-text="Không có dữ liệu"-->
      <!--        outlined-->
      <!--        placeholder="Chọn serials"-->
      <!--        single-line-->
      <!--        small-chips-->
      <!--        return-object-->
      <!--        @input="getSerialInfoBySerialNumber()"-->
      <!--      >-->
      <!--      </v-autocomplete>-->
      <v-text-field
        v-if="!offBizDetail.id"
        v-model="serialNumber"
        class="text-body-1"
        clearable
        dense
        hide-details="auto"
        outlined
        placeholder="Nhập số serial và ấn enter"
        @keyup.enter="getSerialInfoBySerialNumber()"
      ></v-text-field>
      <!-- Start: Serial info -->
      <template v-if="serialInfo && serialInfo.product_info">
        <!-- Start: Serial detail -->
        <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
          <div class="mb-1">
            <span class="font-weight-bold">Serial: </span>
            <span>{{ serialInfo.serial_number }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Tên sản phẩm: </span>
            <span>{{ serialInfo.product_name }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">SKU:</span>
            {{ serialInfo.option_sku }} -
            <span
              class="text-subtitle"
              v-html="
                serialInfo.product_info
                  ? serialInfo.product_info.option_name
                  : ''
              "
            ></span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Giá bán: </span>
            <span>{{
              serialInfo.product_info.sold_price | formatCurrency
            }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Gói bảo hành: </span>
            <span>{{ serialInfo.guarantee_info.name }}</span>
          </div>
        </v-card>
        <!-- End: Serial detail -->
        <!-- Start: PO info -->
        <v-card
          v-if="serialInfo.bought_by"
          class="grey lighten-4 px-5 py-4 mt-4"
          flat
        >
          <div class="mb-1">
            <span class="font-weight-bold">Nhà cung cấp: </span>
            <span>{{ "NCC002" }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">P/O: </span>
            <span>{{ "PO9032" }}</span>
          </div>
          <div>
            <span class="font-weight-bold">Phiếu nhập: </span>
            <span>{{ serialInfo.bought_by.hdb_code }}</span>
          </div>
          <div>
            <span class="font-weight-bold">Nhập bởi: </span>
            <span>{{ serialInfo.bought_by.name }}</span>
          </div>
        </v-card>
        <!-- End: PO info -->
      </template>
      <template v-else>
        <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
          <div class="text-center grey--text text--darken-2">
            Chưa có máy được chọn.
          </div>
        </v-card>
      </template>
      <!-- End: Serial info -->
    </div>
    <!-- End: Serial-->
    <v-divider class="mt-5 mb-4"></v-divider>
    <!-- Start: Status -->
    <div v-if="!offBizDetail.id" class="mt-4">
      <div class="font-weight-bold mb-2">Tình trạng máy</div>
      <v-textarea
        v-model="offBizDetail.tinh_trang_may"
        class="text-body-1"
        :disabled="
          offBizDetail.selected_action !== 1 ||
            (offBizDetail && offBizDetail.step === 4)
        "
        dense
        hide-details="auto"
        placeholder="Mô tả tình trạng tại đây"
        outlined
      ></v-textarea>
      <div class="d-flex flex-row align-center justify-space-between mt-3">
        <div>Đính kèm file</div>
        <v-btn
          class="cyan lighten-5 primary--text text-body-2 font-weight-bold text-none rounded"
          depressed
          small
          :loading="loading"
          @click="$refs.file.click()"
        >
          <v-icon class="mr-1" left>mdi-upload-outline</v-icon>
          Tải lên
        </v-btn>
        <input
          type="file"
          hidden
          ref="file"
          accept=".png, .jpg"
          multiple
          @change="handleFileUpload"
        />
      </div>

      <div
        v-if="offBizDetail.files && offBizDetail.files.length"
        class="d-flex flex-col gap-2 mt-4"
      >
        <div
          v-for="(file, index) in offBizDetail.files"
          :key="file + index"
          class="d-flex gap-2"
        >
          <a
            v-if="file"
            class="text-body-1 hover-underline"
            :href="file"
            target="_blank"
            rel="noopener noreferrer"
            style="width: 80%;"
            >{{ file.split("/").pop() }}</a
          >

          <v-icon class="cursor-pointer" @click="handleRemoveFile(file)">
            mdi-close-circle</v-icon
          >
        </div>
      </div>
    </div>
    <!-- End: Status -->
    <modal-add-reason @addReason="handleAddReason" />
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import MediaService from "@/core/service/media.service";
import ModalAddReason from "@/core/components/modals/ModalGuaranteeRepair/ModalAddReason.vue";
export default {
  components: {
    ModalAddReason
  },

  data() {
    return {
      serialNumber: null,
      file: null,
      key: 0,
      loading: false,
      isUpdateDueDate: false
    };
  },

  computed: {
    roles() {
      return this.$store.getters["ROLE/rolesName"];
    },
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    offBizDetail() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/offBizDetail"];
    },
    statusRequest() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/statusRequest"];
    },
    serialInfo() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/serialInfo"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    employeesSameBranchWithUser() {
      let employeesSameBranchWithUsers = [];
      if (this.user && this.user.branch_id && this.employees) {
        this.employees.map(item => {
          if (item.branch_id === this.user.branch_id)
            employeesSameBranchWithUsers.unshift(item);
        });
        this.employees.map(item => {
          if (item.branch_id !== this.user.branch_id)
            employeesSameBranchWithUsers.push(item);
        });
        return employeesSameBranchWithUsers;
      } else return this.employees;
    },
    dateDueDate: {
      get() {
        return this.offBizDetail.due_date
          ? dayjs(this.offBizDetail.due_date).format("YYYY-MM-DD")
          : null;
      },
      set(val) {
        if (val) {
          this.offBizDetail.due_date = val;
          this.offBizDetail.reason = "";
          this.key++;
        }
      }
    }
  },
  watch: {
    serialNumber(val) {
      if (!val || val === "") {
        this.offBizDetail.serial_id = null;
        this.$store.dispatch("INTERNAL_GUARANTEE_NOTE/setSerialInfo", null);
      }
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  created() {
    const route = this.$route;

    if (this.employees.length === 0) {
      this.$store.dispatch("EMPLOYEE/getAllEmployees");
    }
    if (this.branches.length === 0) {
      this.$store.dispatch("BRANCH/getAllBranches");
    }
    if (this.serialInfo) {
      this.serialNumber = this.serialInfo.serial_number;
      this.offBizDetail.serial_id = this.serialInfo.id;
    }
    if (route.query && route.query.serial_number) {
      this.serialNumber = route.query.serial_number;
    }
  },
  methods: {
    compareDateWithToday(d) {
      let converter = d;
      converter = converter.split("/");
      converter = `${converter[2]}-${converter[1]}-${converter[0]}`;

      const date = new Date(converter),
        today = new Date();

      date.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      return date >= today;
    },
    getToday() {
      const date = new Date(),
        hour = date
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = date
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        dd = date
          .getDate()
          .toString()
          .padStart(2, "0"),
        mm = (date.getMonth() + 1).toString().padStart(2, "0"),
        yyyy = date.getFullYear();

      return `${hour}:${mins} ${dd}/${mm}/${yyyy}`;
    },

    async getSerialInfoBySerialNumber() {
      if (!this.serialNumber || this.serialNumber === "") return;
      else {
        await this.$store.dispatch(
          "INTERNAL_GUARANTEE_NOTE/getSerialInfoBySerialNumber",
          {
            serial_number: this.serialNumber
          }
        );
        if (
          this.statusRequest.value === "success-getSerialInfoBySerialNumber"
        ) {
          this.offBizDetail.serial_id = this.serialInfo.id;
        }

        if (this.statusRequest.value === "error-getSerialInfoBySerialNumber") {
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message:
                "Serial này còn hàng trong hệ thống, hoặc đang nằm trong phiếu bảo hành, sửa chữa khác."
            }
          });
        }
      }
    },
    getNameRole(role) {
      return this.roles.filter(item => item.value === role)[0]["text"];
    },

    async handleFileUpload(event) {
      this.loading = true;
      const files = event.target.files;
      if (!files || files.length === 0) return;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }
      this.$refs.file.value = null;

      try {
        const response = await MediaService.uploadMultipleImages(formData);

        this.offBizDetail.files = [
          ...(this.offBizDetail.files ? this.offBizDetail.files : []),
          ...response.data
        ];
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    handleRemoveFile(file) {
      this.offBizDetail.files = this.offBizDetail.files.filter(f => f !== file);
    },

    showModalAddRoleServer() {
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setBranchIDModelAddRoleServer",
        this.offBizDetail && this.offBizDetail.branch_id
          ? this.offBizDetail.branch_id
          : null
      );
      this.$modal.show({
        name: "modal-add-role-server",
        payload: {
          model_id:
            this.offBizDetail && this.offBizDetail.id
              ? this.offBizDetail.id
              : null,
          model_name: "App\\Models\\OffBiz",
          dataNamespaced: "INTERNAL_GUARANTEE_NOTE/getOffBizById",
          dataRequest: this.offBizDetail.id
        }
      });
    },

    openPicker() {
      this.isUpdateDueDate = true;
    },

    closePicker() {
      this.isUpdateDueDate = false;
    },

    changeDueDate() {
      if (this.isUpdateDueDate && this.offBizDetail.id) {
        this.$modal.show({
          name: "modal-add-reason"
        });
      }
    },

    handleAddReason(reason) {
      this.offBizDetail.reason = reason;
      this.key++;
    }
  }
};
</script>

<style lang="scss" scoped>
.hover-underline:hover {
  text-decoration: underline;
}
.gap-1 {
  gap: 4px;
}
.gap-3 {
  gap: 12px;
}
.gap-2 {
  gap: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 24px);
}
.text-subtitle {
  &::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
</style>
